import cx from "classnames";
import { ExternalLink } from "components/ExternalLink";
import { VerticalLine } from "components/VerticalLine";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import { useI18next } from "gatsby-plugin-react-i18next";
import { useMetadata } from "hooks/useMetadata";
import React, { Suspense, useState } from "react";
import { isMobile } from "react-device-detect";

import { useMediaQuery } from "@react-hookz/web";

const Video = React.lazy(() =>
    import("components/HomeBillboardVideo").then((mod) => ({
        default: mod.HomeBillboardVideo,
    }))
);

const Query = graphql`
    query HomeBillboard {
        logo: allFile(
            filter: {
                name: { eq: "logo-black" }
                sourceInstanceName: { eq: "images" }
            }
        ) {
            edges {
                node {
                    relativeDirectory
                    childImageSharp {
                        gatsbyImageData(
                            placeholder: BLURRED
                            quality: 90
                            sizes: "(max-width: 1024px) 190px, 480px"
                        )
                    }
                }
            }
        }
    }
`;

const KV = () => {
    const ALT = "Key Visual";

    return (
        <>
            <div className="hidden lg:block">
                <StaticImage
                    src="../images/kv.png"
                    alt={ALT}
                    loading="eager"
                    objectFit="contain"
                    placeholder="none"
                    className="mt-header"
                />
            </div>
            <div className="lg:hidden">
                <StaticImage
                    src="../images/kv-mobile.png"
                    alt={ALT}
                    loading="eager"
                    placeholder="none"
                    objectFit="cover"
                    className="h-full mt-12"
                />
            </div>
        </>
    );
};

function Logo(props: {
    src?: Queries.HomeBillboardQuery["logo"]["edges"][number];
    className?: string;
}) {
    const metadata = useMetadata();

    if (!props.src?.node?.childImageSharp?.gatsbyImageData) return null;

    return (
        <section className={props.className}>
            <h1 className="sr-only">{metadata?.title}</h1>
            <GatsbyImage
                alt="Logo"
                image={props.src.node.childImageSharp.gatsbyImageData}
                className="w-[190px] xl:w-[252px]"
            />
        </section>
    );
}

const DownloadButton = () => {
    const isTablet = useMediaQuery("(min-width: 768px)", true);
    return (
        <div
            className="mt-3 inline-flex gap-1 lg:mb-16">
            <ExternalLink href="https://app.adjust.com/17dchwoj" className="md:w-[270px]" rel="noopener">
                {!isTablet && (
                    <StaticImage
                        width={156}
                        src="../images/app-store-badge.png"
                        alt="App Store Badge"
                    />
                )}
                {isTablet && (
                    <StaticImage
                        width={270}
                        src="../images/app-store-badge.png"
                        alt="App Store Badge"
                    />
                )}
            </ExternalLink>
            <ExternalLink href="https://app.adjust.com/17dchwoj" className="md:w-[270px]" rel="noopener">
                {!isTablet && (
                    <StaticImage
                        width={156}
                        src="../images/google-play-badge.png"
                        alt="Google Play Badge"
                    />
                )}
                {isTablet && (
                    <StaticImage
                        width={270}
                        src="../images/google-play-badge.png"
                        alt="Google Play Badge"
                    />
                )}
            </ExternalLink>
        </div>
    )
}

export const HomeBillboard = () => {
    const isDesktop = useMediaQuery("(min-width: 1024px)", true);
    const { logo } = useStaticQuery<Queries.HomeBillboardQuery>(Query);
    const [played, setPlayed] = useState(false);
    const { language } = useI18next();
    const currentLogo = logo.edges.find(
        ({ node }) => node.relativeDirectory === language
    );

    return (
        <>
            <div className="grid">
                <div className="col-span-full row-span-full">
                    <div className="relative mx-auto text-center">
                        <KV />
                        <div className="absolute bottom-8 left-1/2 -translate-x-1/2 transform text-center lg:bottom-0 z-20">
                            {played && <DownloadButton />}
                            {/* lg logo is outside */}
                            {<Logo src={currentLogo} className="lg:hidden" />}
                        </div>
                        <div className="absolute max-w-[1736px] w-full left-1/2 -translate-x-1/2 bottom-16 hidden lg:flex z-20">
                            <Logo
                                src={currentLogo}
                                className="left-0"
                            />
                        </div>

                        <div
                            className={cx(
                                "absolute w-full top-0 h-full transition-opacity [&_video]:object-cover",
                                { "pointer-events-none opacity-0": played }
                            )}
                        >
                            <div className="absolute top-0 z-10 h-[156px] w-full bg-gradient-to-b from-white" />
                            <Suspense fallback={<div />}>
                                <Video
                                    playing
                                    url={isDesktop ? "/index-kv.mp4" : "/index-kv-m.mp4"}
                                    onEnded={() => setPlayed(true)}
                                />
                            </Suspense>
                            <div className="absolute bottom-[-1px] z-10 h-[294px] w-full bg-gradient-to-t from-white" />
                        </div>
                    </div>
                </div>
            </div>
            <VerticalLine className="mt-24 mb-16 md:mt-48 lg:hidden" />
            <div className="hidden lg:block h-32" />
        </>
    );
};

import "swiper/css";
import React, { useState } from "react";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import { useMediaQuery } from "@react-hookz/web";
import { Swiper, SwiperSlide } from "swiper/react";
import type SwiperClass from "swiper/types/swiper-class";
import cx from "classnames";
import { StrokeHeading } from "components/StrokeHeading";
import { ExternalLink } from "components/ExternalLink";
import { VerticalLine } from "components/VerticalLine";
import { ReactComponent as DoubleArrow } from "icons/double-arrow-left.svg";

type Props = { news: Queries.HomeQuery["news"] };

function Heading() {
    // noinspection AllyJsxHardcodedStringInspection
    return (
        <StrokeHeading as="h2" className="mb-3">
            News
        </StrokeHeading>
    );
}

function Inner(props: { image: IGatsbyImageData | undefined; node: any }) {
    return (
        <>
            {props.image && (
                <GatsbyImage alt="News thumbnail" image={props.image} />
            )}
            <h3 className="text-2xl lg:text-3.5xl lg:leading-[3rem] font-bold mt-6">
                {props.node.frontmatter?.title}
            </h3>
        </>
    );
}

function News(props: {
    node: Props["news"]["edges"][number]["node"];
    mobile: boolean;
}) {
    const image = props.mobile
        ? props.node.frontmatter?.thumbnailMobile?.childImageSharp
              ?.gatsbyImageData
        : props.node.frontmatter?.thumbnail?.childImageSharp?.gatsbyImageData;
    return !!props.node?.frontmatter?.url ? (
        <ExternalLink
            href={props.node.frontmatter?.url ?? ""}
            className="block"
        >
            <Inner image={image} node={props.node} />
        </ExternalLink>
    ) : (
        <div>
            <Inner image={image} node={props.node} />
        </div>
    );
}

function Border() {
    return <div className="border-t-10 mx-10 md:mx-0" />;
}

function Controller(
    props: React.ButtonHTMLAttributes<HTMLButtonElement> & {
        direction: "prev" | "next";
        swiper?: SwiperClass;
    }
) {
    const handleClick = () => {
        props.direction === "next"
            ? props.swiper?.slideNext()
            : props.swiper?.slidePrev();
    };
    return (
        <div
            className={cx(
                "hidden 2xl:inline-block shrink-0 pt-[167.5px]",
                props.className
            )}
        >
            <button type="button" onClick={handleClick}>
                <DoubleArrow
                    width={84}
                    height={110}
                    className={cx({
                        "transform rotate-180": props.direction === "next",
                    })}
                />
            </button>
        </div>
    );
}

export const HomeNews: React.FC<Props> = ({ news }) => {
    const [swiper, setSwiper] = useState<SwiperClass>();
    const isMobile = !useMediaQuery("(min-width: 768px)", true);

    return (
        <div className="bg-no-repeat bg-[url(/images/news-aura.svg)] bg-bottom bg-[position:180vw] lg:bg-[right_4rem_top_6rem]">
            <div className="max-w-wide mx-auto text-center px-4">
                <Heading />
                <Border />
                <div className="my-9">
                    <div className="2xl:flex justify-center">
                        <Controller direction="prev" swiper={swiper} />
                        <div className="max-w-[1230px] grow mx-auto 2xl:mx-10">
                            <div>
                                <Swiper spaceBetween={36} onSwiper={setSwiper}>
                                    {news.edges.map(({ node }) => (
                                        <SwiperSlide key={node.id}>
                                            <News
                                                node={node}
                                                mobile={isMobile}
                                            />
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </div>
                        </div>
                        <Controller direction="next" swiper={swiper} />
                    </div>
                </div>
                <Border />
                <VerticalLine className="mt-24 lg:mt-48 mb-2" />
            </div>
        </div>
    );
};

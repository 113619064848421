import React, { Fragment, useState, useMemo, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useMediaQuery } from "@react-hookz/web";
import { Swiper, SwiperSlide } from 'swiper/react';
import type SwiperClass from "swiper/types/swiper-class";
import { StrokeHeading } from "./StrokeHeading";
import { VerticalLine } from "components/VerticalLine";
import _ReactPlayer, { ReactPlayerProps } from "react-player/lazy";
import { ReactComponent as IndicatorNormal } from 'icons/indicator-normal.svg';
import { ReactComponent as IndicatorActive } from 'icons/indicator-active.svg';

import 'swiper/css';
import 'swiper/css/pagination';

const Player = _ReactPlayer as unknown as React.FC<ReactPlayerProps>;

function getYoutubeThumbnail(link: string) {
    const url = new URL(link);

    // escape for non-youtube video
    if (!url.hostname.includes('youtu.be') && !url.hostname.includes('youtube.com')) {
        return '';
    }

    const videoId = url.hostname === 'youtu.be'
        ? url.pathname.slice(1)
        : new URLSearchParams(url.search).get('v') ?? '';
    return videoId ? `https://i3.ytimg.com/vi/${videoId}/maxresdefault.jpg` : '';
}

function Heading() {
  // noinspection AllyJsxHardcodedStringInspection
  return (
      <StrokeHeading as="h2" className="mb-3">
          Trailer
      </StrokeHeading>
  );
}

function Border() {
    return <div className="border-t-10 mx-10 md:mx-0" />;
}

function Pagination(
    props: { availableIndexes: number[], activeIndex: number, swiper?: SwiperClass }
) {
    const indicators = props.availableIndexes.map((index) => ({
        index, isActive: props.activeIndex === index,
    }));

    const handleClick = (index: number) => {
        props.swiper?.slideTo(index);
    };

    return (
        <div className="w-full absolute bottom-7 flex justify-center gap-2">
        {indicators.map(({ index, isActive }) => (
            <div key={index} className="box-border text-sm cursor-pointer" onClick={() => handleClick(index)}>
            {isActive ? <IndicatorActive /> : <IndicatorNormal />}
            </div>
        ))}
        </div>
    )
}

function TrailerImageWithModal(props: {
    link: string;
    iconURL?: string;
}) {
    const [open, setOpen] = useState(false);
    const icon = props.iconURL || getYoutubeThumbnail(props.link);

    const handleOpen = () => setOpen(true);

    const handleClose = () => setOpen(false);

    return (
        <>
            <img className="w-full h-auto cursor-pointer" src={icon} onClick={handleOpen} />
            <Transition appear show={open} as={Fragment}>
                <Dialog as="div" className="relative z-20" onClose={handleClose}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black/80" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-screen max-w-[90vw] max-h-[90vh] aspect-video">
                                    <Player
                                        url={props.link}
                                        width="100%"
                                        height="100%"
                                    />
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}

type Props = { trailer: Queries.HomeQuery["trailer"] };

export const HomeTrailer: React.FC<Props> = ({ trailer }) => {
    const isDesktop = useMediaQuery("(min-width: 1024px)", true);
    const isTablet = useMediaQuery("(min-width: 768px)", true);
    const paging = useMemo(
        () => isDesktop ? 3 : (isTablet ? 2 : 1),
        [isDesktop, isTablet],
    );
    const [swiper, setSwiper] = useState<SwiperClass>();
    const [activeIndex, setActiveIndex] = useState<number>(0);

    // Because slides were grouped, its indexing logic is
    // 1, 2, 3 when slidesPerGroup = 1
    // 2, 4, 6 when slidesPerGroup = 2
    // 3, 6, 9 when slidesPerGroup = 3
    // Reference: https://github.com/nolimits4web/swiper/issues/2208
    const availableIndexes = useMemo(
        () => trailer.edges
            .map((_, index) => index + paging)
            .filter((index) => index % paging === 0),
        [paging],
    );

    useEffect(() => {
        setActiveIndex(paging);
        swiper?.slideTo(paging);
    }, [paging, swiper]);

    return (
        <div className="bg-no-repeat bg-[url(/images/trailer-aura.svg)] mb-[72px]">
            <div className="max-w-wide mx-auto text-center px-4">
                <Heading />
                <Border />
                <div className="mt-14 mb-[72px] flex justify-center">
                    <div className="relative w-full max-w-[1536px] px-7 pt-6 pb-16 bg-black flex justify-center items-center">
                        <Swiper
                            onSwiper={setSwiper}
                            onActiveIndexChange={(swiper) => setActiveIndex(swiper.activeIndex)}
                            slidesPerView={paging}
                            spaceBetween={24}
                            // These configuration will make swiper behave pagination with blank slides.
                            slidesPerGroup={paging}
                            loop
                            loopFillGroupWithBlank
                        >
                            {trailer.edges.map(({ node }) => (
                                <SwiperSlide>
                                    <TrailerImageWithModal
                                        link={node.frontmatter?.link ?? ''}
                                        iconURL={node.frontmatter?.icon?.publicURL ?? ''}
                                    />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                        <Pagination availableIndexes={availableIndexes} activeIndex={activeIndex} swiper={swiper} />
                    </div>
                </div>
                <VerticalLine />
            </div>
        </div>
    )
}